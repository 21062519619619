/* App.css */

/* Header & Body */
header {
  text-align: center;
  padding: 2px;
  background-image: linear-gradient(to bottom, #2c3037, #000000); /* Gradient from Gold to Black */
  border-bottom: 3.5px solid black;
}

.title {
  font-family: cursive;
  font-size: 4em;
  background-image: linear-gradient(to bottom, #d1e389, #ffffff); /* Gradient from Light Green to White */
  color: #e22a22;
  margin: 0px;
  display: inline-block;
  border: 4px solid #000;
  border-radius: 10px;
  padding: 10px;
}

.author-info {
  padding: 15px;
  border-bottom: 4px solid #000;
  background-image: linear-gradient(to bottom, #ffefd5, #ffffff); /* Gradient from Papaya Whip to White */
  border-left: 4px solid #000;
  border-right: 4px solid #000;
  display: inline-block;
  margin-bottom: 10px;
}


html{
  height:100%;
}

body {
  font-family: 'Pacifico', cursive;
  background-image: linear-gradient(to bottom, #3ea2af, #ffffff);
  margin: 0;
  padding: 0;
  /* max-width: 110%; */
  overflow-x: clip;
}

/* Sections */
.userSection {
  background-image: linear-gradient(to bottom, #3cb1b1, #ffffff); /* Gradient from Pale Turquoise to White */
  border: 2px solid black;
  max-width: 100%;
}

.userButtons {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  /* margin-bottom: 10px; */
  border-radius: 5px; /* Add border radius for rounded corners */
  font-size: 1.1em;
  margin-left: 25px;
  
}

.toggleInfoSection {
  cursor: pointer;
  font-size: 1.3em;

  max-width: 100%;
  max-height: 100%;
  text-align: center;
  overflow: hidden;
  background-color: #f8f8ff;
}

.toggleInfoSection:hover {
  background-color: #f8f8ffe8; /* Darker orange on hover */
}

.toggleText {
  font-size: 1.6em;
  flex-grow: 1;
}

.createChallengeSection {
  background-image: linear-gradient(to bottom, #f5deb3, #ffffff); /* Gradient from Moccasin to White */
  text-align: center;
  padding: 20px;
  border: 3.5px solid black;
}



/* Input Styles */
label {
  display: inline-block;
  width: 95px;
  border: 3px solid black;
  border-right: none;
  padding: 8px;
  padding-right: 15px;
  font-size: 1.1em;
  background-color: #fff;  
  margin-top: 5px;
}

input {
  border: 3px solid black;
  padding: 8px;
  font-size: 1.1em;
  font-family: 'Comic Sans MS', Comic;
  background-color: #ffdead; /* Navajo White */
  margin-top: 5px;
}


/* ChessChallengesTable Styles */
table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border: 3px solid #000;
}

th {
  border: 2px solid #000;
  background-color: #219dc7; /* Light Blue */
  color: #000;
  padding: 15px;
  font-size: 22px; /* Set your desired font size here */
}

td {
  padding: 18px;
  border-right: 2px solid #3498db;
  border-bottom: 2px solid #3498db;
  font-size: 20px;
}

tbody tr {
  background-color: #f5f5f5; /* White Smoke */
}

tbody tr:nth-child(even) {
  background-color: #fafafa; /* Light Gray */
}

tbody tr:hover {
  background-color: #dcdcdc; /* Gainsboro */
}


/* Components */
.textOutliner {
  padding: 10px 20px;
  border: 2px solid black;
  color: white;
  border-radius: 5px;
}

.smallHeader {
  padding: 5px 20px;
  font-size: 30px;
  background-color: #ffffe0; /* Light Yellow */
  display: inline-block;
  border: 2px solid black;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  border-radius: 3px
}

.highlightedText {
  background-color: #f0f0f0; /* Light Gray */
  padding: 2%;
  display: inline-flex;
  border: 3px solid black;
  border-radius: 3px;
  text-align: center
}

.infoBox {
  padding: 1% 10%;
  font-size: 30px;
  background-color: #cbcb2ed6; /* Light Yellow */
  display: inline-block;
  border: 2px solid black;
  border-radius: 7px;
  font-size: 20px;
}

.infoBox:hover {
  background-color: #d3d3be; /* Light Yellow */
}

.close {
  color: #000000;
  float: right;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: orange;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px; /* Adjust the width as needed */
}

.hover {
  position: absolute;
  top: 75%;
  left: 110%;
  height: 50%;
  background-color: white;
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000
}


/* Modals */
.modal {
  /* display: inline-block; */
  padding: 8px 15px;
  /* margin-top: 10px; */
  border: 3px solid black;
  border-radius: 10px;
  /* margin-bottom: 20px; */
  /* align-items: center; */
  /* justify-content: space-between; */
  white-space: nowrap;
}

.modalUser {
  background-color: #2ee7a6; /* Light Coral */
  /* margin-left: 20px;  */
}

.modalCreate {
  width: 700px;
  /* height: 100%; */
}

.modalLogin {
  width: 450px;
  /* height: 100%; */
}

.modalChallenge {
  background-color: #87ceeb; /* Sky Blue */
}


.deleteModalContent {
  top: 75%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}



/* Styles & Designs */
.warning {
  color: #ff6347;
}

.important {
  color: rgb(0, 89, 255);
}

.available {
  background-color: #008080; 
}

.completed {
  background-color: #260080; 
}

.accepted {
  background-color: Purple;
}

.ready1 {
  background-color: #008037;
  cursor: pointer;
}

.ready2 {
  background-color: #e21700;
  cursor: pointer;
}

.ready3 {
  background-color: #008037;
  cursor: pointer;
}

.lichess {
  background-color: #32bc6e;
  cursor: pointer;
}

.highlight {
  background-color: #fbff00;
}

.notification-error {
  background-color: rgb(226, 63, 42);
}

.notification-success {
  background-color: green;
}


/* User Details Box */

/* .detail-container { */
  /* display: inline-flex; */
/* } */

.detail {
  border-radius: 5px;
  font-size: 1.1em;
  margin-bottom: 5px;
  /* display: inline-block; */
  /* white-space: nowrap; */
  /* display: flex; */

  /* transform: translate(-100px); */
  /* position: absolute; */
  /* position: relative; */
}

.detail-box,
.detail-value {
  border: 1px solid #000;
  font-size: 1.1em;
  padding: 20px 35px;
  display: inline-block;
}

.detail-box {
  background-color: #1fdddd; /* Moccasin */
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  /* margin-left: -7%; */
}

.detail-value {
  background-color: #c3f227; /* Papaya Whip */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


/* Buttons */
button {
  font-family: Bradley Hand, cursive;
  font-size: 1.1em;
  
  padding: 10px 20px; 
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer; 
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap
}

.createProfButton {
  background-color: #ffa500; /* Orange */
  color: #0d31c0; /* White text color for better contrast */
  margin-right: 50px;
  margin-bottom: 25px
}

.createProfButton:hover {
  background-color: #ff7f00; /* Darker orange on hover */
}

.createChallengeButton {
  background-color: #008080; /* Teal */
  color: #fff; 
  padding: 10px;
  margin-bottom: 25px
}

.resetButton {
  background-color: #00d100;
  color: #fff; 
  margin-left: 5%;
}

.resetButton:hover {
  background-color: #009c00;
}


.logButton {
  background-color: #7fffd4; /* Aquamarine */
  color: #0d31c0; 
  margin-right: 50px;
  margin-bottom: 25px
}

.logButton:hover {
  background-color: #00a1bd; /* Darker orange on hover */
}



.submitButton {
  background-color: #3498db; /* Dodger Blue */
  color: #ffffff;
  margin-top: 10px;
}

.acceptButton {
  background-color: #2aa545; /* Brown */
  color: #fff; 
}

.deleteButton {
  background-color: #c81515; /* Brown */
  color: #fff; 
}

.cancelButton {
  background-color: hsl(202, 6%, 62%); /* Brown */
  color: #fff; 
}

.generateButton {
  background-color: #c65922; /* Dark Orange */
  color: #fff; 
}





 /* ESCROW PAGE STYLE */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.escrow-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.info-text {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}



/* DeleteProfileModal.css */
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: auto; /* Adjust the width as needed */
  height: auto; /* Adjust the height as needed */
  background-color: white; /* Ensure it has a background color */
  padding: 20px; /* Optional: Add some padding */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Optional: Add some shadow for better visibility */
  border-radius: 10px; /* Optional: Add rounded corners */
}